<script>
import router from "@/router";
import { layoutComputed } from "@/state/helpers";

import Topbar from "@/components/topbar";
import Sidebar from "@/components/side-bar";
import RightSidebar from "@/components/right-sidebar";
import Footer from "@/components/footer";

export default {
  data() {
    return {
       isMenuCondensed: false,
       routeHistory: JSON.parse(localStorage.getItem('routeHistory')) || [], // Initialize route history from localStorage
       hoseh:false
    }
  },
  components: {
    Topbar,
    Sidebar,
    RightSidebar,
    Footer,
  },
   computed: {
    ...layoutComputed,
  },
  name: "vertical",
  created() {
    document.body.removeAttribute("data-layout");
    document.body.removeAttribute("data-topbar");

     // Initialize route history with the current route if not present
    this.addRouteToHistory(this.$route);

    // Listen for route changes to update route history
    this.$router.afterEach((to, from) => {
      console.log(to, from);
      this.addRouteToHistory(to);
    });
  },
  methods: {
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    addRouteToHistory(route) {
      if (route.name=='dashboard'){
        return;
      }
      const routeObj = {
        fullPath: route.fullPath,
        name: route.name || route.fullPath // Fallback to fullPath if name is not available
      };

      // Check if a route with the same name already exists
      if (!this.routeHistory.some(r => r.name === routeObj.name)) {
        this.routeHistory.push(routeObj);
        localStorage.setItem('routeHistory', JSON.stringify(this.routeHistory)); // Update localStorage
      }
    },
     removeRouteFromHistory(route) {
      this.routeHistory = this.routeHistory.filter(r => r.fullPath !== route.fullPath);
      localStorage.setItem('routeHistory', JSON.stringify(this.routeHistory)); // Update localStorage
    }
  },
  
};
</script>

<template>
  <!-- Begin page -->
  <div id="layout-wrapper">
    <Topbar />
    <Sidebar :is-condensed="isMenuCondensed" :type="leftSidebarType" :width="layoutWidth"/>
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <!-- Render route history as buttons -->
          <div class="route-history mb-2" v-if="hoseh" >
             <button v-for="(route, index) in routeHistory" :key="index" 
              :class="['d-inline-flex  align-items-center','btn pt-2 pb-2', 'me-1 mb-2', { 'bg-primary text-white fw-medium': route.fullPath === $route.fullPath, 'bg-white': route.fullPath !== $route.fullPath }]" 
              @click="navigateTo(route)">
             <span class="lh-1 text-capitalize"> {{ route.name }}</span> <span @click="removeRouteFromHistory(route)"><i class="bi bi-x-circle ms-2"></i></span>
            </button>
          </div>
          <slot />
        </div>
      </div>
      <!-- End Page-content -->
      <Footer />
    </div>
    <!-- end main content-->
    <RightSidebar />
  </div>
</template>