<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import simplebar from "simplebar-vue";
import Common from "@/components/common";
import appConfig from "@/app.config";
import { EncryptStorage } from 'encrypt-storage';
export const encryptStorage = new EncryptStorage('secret-key-value', {
  prefix: appConfig.prefix,
});

/**
 * Sidebar component
 */
export default {
  data() {
    return {
      modules:[],
      menuItems: [
        {
          id: 1,
          label: "Menu", //menuitems.menu.text"
          isTitle: true,
          module: "base",
        },
        {
          id: 2,
          module: "dashboard",
          label: "Dashboard",
          icon: "bi bi-house-door",
          // badge: {
          //     variant: "primary",
          //     text: "2"
          // },
          link: "/",
        },
      ],
      loading: true,
      account_type: "",
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  components: {
    simplebar,
    Common
  },
  mounted: async function () {
    var menuRef = new MetisMenu("#side-menu");
    // eslint-disable-next-line no-unused-vars
    // this.accessToken = this.$refs.commonFunc.getToken()
    // this.accessPhone = this.$refs.commonFunc.getUsername()
   
    const data =  await this.$refs.commonFunc.getFullData()
    console.log(data)
    if (data.account_type == "admin"){
      this.menuItems[0].label = "Admin"
    }
    else if (data.account_type == "reseller"){
      this.menuItems[0].label = "Reseller"
    }else{
      this.menuItems[0].label = "Merchant"
    }
    setTimeout(() => {
      this._activateMenuDropdown();
    }, 0);
    
    this.$router.afterEach(() => {
      this._activateMenuDropdown();
    });
    this.loading=false;
    console.log(menuRef)
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar");
              document.body.removeAttribute("data-sidebar-size");
              break;
            case "light":
              document.body.removeAttribute("data-sidebar");
              document.body.removeAttribute("data-sidebar-size");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "compact":
              document.body.setAttribute("data-sidebar-size", "small");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-topbar", "dark");
              break;
            case "icon":
              document.body.setAttribute("data-keep-enlarged", "true");
              document.body.classList.add("vertical-collpsed");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar", "dark");
              break;
            case "colored":
              document.body.setAttribute("data-sidebar", "colored");
              document.body.removeAttribute("data-keep-enlarged");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-sidebar-size");
              break;
            default:
              document.body.setAttribute("data-sidebar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
    $route: {
      handler: "onRoutechange",
      immediate: false,
      deep: true,
    },
  },
  created(){
    console.log("sssssss")
    this.loading=true
    const modules = encryptStorage.getItem('mds')
    this.modules = modules
    const userdata = encryptStorage.getItem('userdata')
    this.account_type=userdata.account_type
    this.addMenu();
  },
  methods: {
    addMenu(){
      var ModuleName = this.modules.map((el) => el.name);
      console.log("ModuleName -> "+ ModuleName)
      var ChangeOrderList = []
      // Your desired order
      // "settlements",
      // "withdrawal",
      const desiredOrder = [
          "payin",
          "payout",
          "top_up",
          "account_management",
          "my_contracts",
          "reports",
          "technical",
          "sub_accounts",
          "administrator",
      ];
      // Process the array elements in the desired order
      desiredOrder.forEach(element => {
          if (ModuleName.includes(element)) {
              // Process the element here
              ChangeOrderList.push(element)
          }
      });
      ChangeOrderList.forEach((value) => {
        if (value=='payin'){
          
          this.menuItems.push(
            {
              id: 3,
              label: "Payin",
              module: "payin",
              icon: "bi bi-arrow-down-circle",
              subItems: [
                  {
                      id: 31,
                      label: "Transactions",
                      link: "/transactions",
                      parentId: 3
                  },
                  {
                      id: 32,
                      label: "Processing Transactions",
                      link: "/transactions/processing-list",
                      parentId: 3
                  }
              ]
            }
          )
        }
        else if (value=='payout'){
          this.menuItems.push(
            {
              id: 4,
              label: "Payout",
              module: "payout",
              icon: "bi bi-arrow-up-circle",
              subItems: [
                  {
                      id: 41,
                      label: "Payouts",
                      link: "/payouts/list",
                      parentId: 4
                  },
                  {
                      id: 42,
                      label: "Pending Payouts",
                      link: "/payouts/pending-list",
                      parentId: 4
                  }
              ]
            }
          )
        }
        else if (value=='settlements'){
          this.menuItems.push(
            {
              id: 5,
              label: "Settlements",
              module: "settlements",
              icon: "bi bi-receipt",
              subItems: [
                  {
                      id: 51,
                      label: "Settlements",
                      link: "/settlements/list",
                      parentId: 5
                  },
                  {
                      id: 52,
                      label: "Pending Settlements",
                      link: "/settlements/pending-list",
                      parentId: 5
                  }
              ]
            }
          )
        }
        else if (value=='withdrawal'){
          this.menuItems.push(
            {
              id: 14,
              label: "Withdrawals",
              module: "withdrawal",
              icon: "bi bi-wallet",
              subItems: [
                  {
                      id: 141,
                      label: "Withdrawals",
                      link: "/withdrawals/list",
                      parentId: 14
                  },
                  {
                      id: 142,
                      label: "Pending Withdrawal",
                      link: "/withdrawals/pending-list",
                      parentId: 14
                  }
              ]
            }
          )
        }
        else if (value=='top_up'){
          this.menuItems.push(
            {
              id: 13,
              label: "Top Up",
              module: "top_up",
              icon: "bi bi-file-arrow-up",
              link: "/top-up/list",
            }
          )
        }
        else if (value=='account_management'){
          this.menuItems.push(
            {
              id: 6,
              label: "A/C Management",
              module: "account_management",
              icon: "bi bi-diagram-3",
              subItems: [{
                id: 61,
                label: "Resellers",
                submodule: "",
                link: "/reseller",
                parentId: 6
              },
              {
                id: 62,
                label: "Merchants",
                submodule: "",
                link: "/merchants",
                parentId: 6
              },
              {
                id: 63,
                label: "Reseller Contracts",
                submodule: "",
                link: "/reseller-contracts",
                parentId: 6
              },
              {
                id: 64,
                label: "Merchant Contracts",
                submodule: "",
                link: "/contracts",
                parentId: 6
              },
              // {
              //     id: 127,
              //     label: "Generate Quotation",
              //     submodule: "",
              //     link: "/generate-quotation",
              //     parentId: 6,
              // }
            ]
            }
          )
        }
        else if (value=='technical'){
          this.menuItems.push(
            {
              id: 16,
              label: "Technical",
              module: "technical",
              icon: "bi bi-file-earmark-text",
              subItems: [
                {
                id: 161,
                label: "API Documentation",
                submodule: "",
                link: "/api-documentation",
                },
                // {
                // id: 162,
                // label: "Technical Information",
                // submodule: "",
                // link: "/technical-information",
                // },
                {
                id: 163,
                label: "Technical Support",
                submodule: "",
                link: "/technical-support",
                },
              ]
            }
          )
        }
        else if (value=='administrator'){
          this.menuItems.push(
            {
              id: 7,
              label: "Administrator",
              module: "admin",
              icon: "bi bi-person-gear",
              subItems: [
                {
                id: 71,
                label: "Company Setting",
                submodule: "company-setting",
                link: "/company-setting"
                },
                {
                id: 72,
                label: "Bots",
                submodule: "bots",
                link: "/bots"
                },
                {
                id: 73,
                label: "Bot Update Logs",
                submodule: "bots_update_logs",
                link: "/bot-update-logs"
                },
                {
                id: 74,
                label: "Bank Accounts",
                submodule: "bank_accounts",
                link: "/bank-accounts"
                },
                {
                id: 75,
                label: "UPI Accounts",
                submodule: "upi_accounts",
                link: "/upi-accounts"
                },
                {
                id: 76,
                label: "Financial Group",
                submodule: "financial_group",
                link: "/financial-group"
                },
                {
                id: 77,
                label: "Admin Logs",
                submodule: "admin_logs",
                link: "/admin-logs"
                }
              ]
            }
          )
        }
        else if (value=='my_contracts'){
          // this.menuItems.push(
          //   {
          //     id: 10,
          //     label: "My Contracts",
          //     module: "my_contracts",
          //     icon: "bi bi-journal-text",
          //     link: "/contracts"
          //   }
          // )
        }
        else if (value=='reports'){
          this.menuItems.push(
            {
              id: 12,
              label: "Reports",
              module: "reports",
              icon: "bi bi-bar-chart-line",
              subItems: [
              ]
            }
          )
        }
        else if (value=='sub_accounts'){
          this.menuItems.push(
            {
              id: 15,
              label: "Sub Accounts",
              module: "sub_accounts",
              icon: "bi bi-people",
              link: "/sub-accounts"
            }
          )
        }
      })
    
      if (this.account_type=='admin'){
        this.menuItems.forEach(element => {
          if (element.module=='reports'){
            element.subItems.splice(element.subItems.length, 0, {
                id: 121,
                label: "Daily Earning Report",
                submodule: "",
                link: "/daily-earning-report",
                parentId: 12,
              },
              {
                id: 122,
                label: "Reseller Earning Statement",
                submodule: "",
                link: "/reseller-earning-statement",
                parentId: 12,
              },
              {
                id: 125,
                label: "Reseller Contract Ledger",
                submodule: "",
                link: "/reseller-contract-acc-ledger",
                parentId: 12
              },
              {
                id: 124,
                label: "Reseller Balance Settlement",
                submodule: "",
                link: "/reseller-balance-settlement",
                parentId: 12,
              },
              {
                id: 123,
                label: "Daily Transactions Report",
                submodule: "",
                link: "/daily-transactions-report",
                parentId: 12,
              },
              {
                id: 127,
                label: "Merchant Contract Ledger",
                submodule: "",
                link: "/contract-acc-ledger",
                parentId: 12,
              },
              {
                id: 126,
                label: "Merchant Balance Settlement",
                submodule: "",
                link: "/merchant-balance-settlement",
                parentId: 12,
              },
              {
                id: 127,
                label: "Merchant Transaction Report",
                submodule: "",
                link: "/merchant-transaction-report",
                parentId: 12,
              },
              {
                id: 128,
                label: "Banks Transaction Report",
                submodule: "",
                link: "/banks-transaction-report",
                parentId: 12,
              },
          
             
              );
          }
        });
      }

      if (this.account_type=='reseller'){
        this.menuItems.forEach(element => {
          if (element.module=='reports'){
            element.subItems.splice(element.subItems.length, 0, 
              {
                id: 121,
                label: "Daily Earning Report",
                submodule: "",
                link: "/daily-earning-report",
                parentId: 12,
              },
              {
                id: 122,
                label: "Reseller Earning Statement",
                submodule: "",
                link: "/reseller-earning-statement",
                parentId: 12,
              },
              {
                id: 124,
                label: "Reseller Balance Settlement",
                submodule: "",
                link: "/reseller-balance-settlement",
                parentId: 12,
              },
              {
                id: 125,
                label: "Reseller Contract Ledger",
                submodule: "",
                link: "/reseller-contract-acc-ledger",
                parentId: 12
              },
              {
                id: 127,
                label: "Merchant Contract Ledger",
                submodule: "",
                link: "/contract-acc-ledger",
                parentId: 12,
              },
              // {
              //   id: 127,
              //   label: "Generate Quotation",
              //   submodule: "",
              //   link: "/generate-quotation",
              //   parentId: 12,
              // }
             
              );
              
            }
        });
        
      }

      if (this.account_type=='merchant'){
        this.menuItems.forEach(element => {
          if (element.module=='reports'){
            element.subItems.splice(element.subItems.length, 0, {
                id: 123,
                label: "Daily Transactions Report",
                submodule: "",
                link: "/daily-transactions-report",
                parentId: 12,
              },
              {
                id: 127,
                label: "Contract Ledger",
                submodule: "",
                link: "/contract-acc-ledger",
                parentId: 12,
              },
              {
                id: 124,
                label: "Balance Settlement",
                submodule: "",
                link: "/merchant-balance-settlement",
                parentId: 12,
              });
          }
        });

        this.menuItems.forEach(element => {
          if (element.module=='technical'){
            element.subItems.splice(element.subItems.length, 0, {
                id: 164,
                label: "Merchant Settings",
                submodule: "",
                link: "/merchant-settings",
                parentId: 12,
              }
             );
          }
        });
      }
      this.menuItems.push(
          {
            id: 99,
            label: "Profile Setting",
            icon: "bi bi-gear",
            link: "/profile-setting"
          }
      )
      // this.menuItems.push(
      //     {
      //       id: 100,
      //       label: "Support",
      //       icon: "uil uil-stethoscope",
      //       link: "/support"
      //     }
      // )
       
    },
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    /**
     * remove active and mm-active class
     */
    _removeAllClass(className) {
      const els = document.getElementsByClassName(className);
      while (els[0]) {
        els[0].classList.remove(className);
      }
    },
    _activateMenuDropdown() {
      this._removeAllClass("mm-active");
      this._removeAllClass("mm-show");

      var links = document.getElementsByClassName("side-nav-link-ref");
      var matchingMenuItem = null;
      const paths = [];

      for (var i = 0; i < links.length; i++) {
        paths.push(links[i]["pathname"]);
      }
      var itemIndex = paths.indexOf(window.location.pathname);
      if (itemIndex === -1) {
        const strIndex = window.location.pathname.lastIndexOf("/");
        const item = window.location.pathname.substr(0, strIndex).toString();
        matchingMenuItem = links[paths.indexOf(item)];
      } else {
        matchingMenuItem = links[itemIndex];
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var parent = matchingMenuItem.parentElement;

        /**
         * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
         * We should come up with non hard coded approach
         */
        if (parent) {
          parent.classList.add("mm-active");
          const parent2 = parent.parentElement.closest("ul");
          if (parent2 && parent2.id !== "side-menu") {
            parent2.classList.add("mm-show");

            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.add("mm-active");
              var childAnchor = parent3.querySelector(".has-arrow");
              var childDropdown = parent3.querySelector(".has-dropdown");
              if (childAnchor) childAnchor.classList.add("mm-active");
              if (childDropdown) childDropdown.classList.add("mm-active");

              const parent4 = parent3.parentElement;
              if (parent4 && parent4.id !== "side-menu") {
                parent4.classList.add("mm-show");
                const parent5 = parent4.parentElement;
                if (parent5 && parent5.id !== "side-menu") {
                  parent5.classList.add("mm-active");
                  const childanchor = parent5.querySelector(".is-parent");
                  if (childanchor && parent5.id !== "side-menu") {
                    childanchor.classList.add("mm-active");
                  }
                }
              }
            }
          }
        }
      }
    },
    onRoutechange() {
      setTimeout(() => {
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition = document.getElementsByClassName(
            "mm-active"
          )[0].offsetTop;
          if (currentPosition > 500)
            this.$refs.currentMenu.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
        }
      }, 300);
    },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <!-- LOGO -->
    <div class="navbar-brand-box">
      <router-link to="/" class="logo logo-dark">
        <span class="logo-sm">
          <img src="@/assets/images/logo-sm.png" alt height="24" class="img-fluid" />
        </span>
        <span class="logo-lg text-center">
          <img src="@/assets/images/logo-light.png" alt height="46" class="mt-2" />
        </span>
      </router-link>

      <router-link to="/" class="logo logo-light">
        <span class="logo-sm ms-0">
          <img src="@/assets/images/logo-sm-light.png" alt height="40" />
        </span>
        <span class="logo-lg">
          <img src="@/assets/images/logo-light.png" alt height="58" />
        </span>
      </router-link>
    </div>

    <button
      type="button"
      @click="toggleMenu"
      class="btn btn-sm px-3 font-size-22 header-item vertical-menu-btn"
    >
    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAT0lEQVR4nO3WwQkAIAwDwIzezesWKuEO8jf4aBIAHpmSZEuS1w9QJK0/MiUBAPLBRTbj0zoatySpKTIlAQDywUU249M6GrckqSkyJQEg1x3OPOvHECpbTAAAAABJRU5ErkJggg==" height="15">
    </button>

    <simplebar class="sidebar-menu-scroll" ref="currentMenu">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
          <template v-for="item in menuItems">
            <li class="menu-title" v-if="item.isTitle" :key="item.id">
             {{item.label}}
            </li>
            <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
              <a
                v-if="hasItems(item)"
                href="javascript:void(0);"
                class="is-parent"
                :class="{
                  'has-arrow': !item.badge,
                  'has-dropdown': item.badge,
                }"
              >
                <i :class="`${item.icon}`" v-if="item.icon"></i>

                <span
                  :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
                  v-if="item.badge"
                  >{{ $t(item.badge.text) }}</span
                >
                <span>{{ item.label }}</span>
              </a>

              <router-link
                :to="item.link"
                v-if="!hasItems(item)"
                class="side-nav-link-ref"
              >
                <i :class="`${item.icon}`" v-if="item.icon"></i>
                <span>{{ item.label }} </span>
                <span
                  :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
                  v-if="item.badge"
                  >{{ $t(item.badge.text) }}</span
                >
              </router-link>

              <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li v-for="(subitem, index) of item.subItems" :key="index">
                  <router-link
                    :to="subitem.link"
                    v-if="!hasItems(subitem)"
                    class="side-nav-link-ref"
                    >{{ subitem.label }}</router-link
                  >
                  <a
                    v-if="hasItems(subitem)"
                    class="side-nav-link-a-ref has-arrow"
                    href="javascript:void(0);"
                    >{{ subitem.label }} </a
                  >
                  <ul
                    v-if="hasItems(subitem)"
                    class="sub-menu mm-collapse"
                    aria-expanded="false"
                  >
                    <li
                      v-for="(subSubitem, index) of subitem.subItems"
                      :key="index"
                    >
                      <router-link
                        :to="subSubitem.link"
                        class="side-nav-link-ref"
                        >{{ subSubitem.label }}</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
      <!-- Sidebar -->
    </simplebar>
    <Common ref="commonFunc" />
  </div>
  <!-- Left Sidebar End -->
  
</template>